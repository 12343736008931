<template>
  <v-card
    elevation="2"
    class="welcome-coupon-card rounded-lg d-flex pa-1"
    :class="type"
    ref="couponCard"
    @click="showDetails"
    :disabled="disabled"
  >
    <div class="d-flex justify-center align-center flex-shrink-1">
      <img
        :src="
          type == 'activeCashbackDiscount'
            ? coupon.product.mediaURL
            : coupon.mediaURL
            ? coupon.mediaURL
            : coupon.media[0].small
        "
        onerror="this.onerror=null;this.src='/no-icon.png'"
        class="coupon-img rounded-lg"
        :alt="
          'Coupon-' +
            (type == 'activeCashbackDiscount' ? coupon.barCode : coupon.name)
        "
      />
    </div>
    <div class="d-flex flex-column justify-center text-center w-100 ml-2 px-1">
      <div class="cashback-coupon-description">
        <!--
        <div class="font-weight-semibold text-body-2 pt-1">
          {{ $t("coupons.discounts.discountCoupon") }}
        </div>
        -->
        <div
          v-if="type == 'activeCashbackDiscount'"
          class="amount"
          :class="`${type + '-name'}`"
        >
          {{ coupon.product.name }}
        </div>
        <div v-else class="amount" :class="`${type + '-name'}`">
          {{ coupon.name }}
        </div>
        <div class="font-weight-regular text-body-3 pb-1">
          <i>{{ $t("coupons.discounts.onSpecificSector") }}</i>
        </div>
        <div
          v-if="type == 'activeCashbackDiscount'"
          class="font-weight-regular text-body-3 pb-1"
          :class="`${type + '-name'}`"
        >
          {{
            $t("coupons.discounts.valid", {
              startDate:coupon.startRetreat,
              endDate: coupon.endRetreat
            })
          }}
        </div>
      </div>
      <div class="flex-grow-1 d-flex align-center mt-2">
        <v-btn
          v-if="type == 'activeCashbackDiscount'"
          rounded
          block
          large
          color="primary"
          depressed
          @click.stop="showDetails"
          class="white--text coupon-button-color"
          >{{ $t("coupons.discounts.view") }}</v-btn
        >
        <div v-else-if="type == 'incomingDiscount'" class="font-weight-bold">
          {{ $t("coupons.discounts.availableNextWeek") }}
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.welcome-coupon-card {
  min-height: 160px;
  .coupon-img {
    width: 150px;
  }
  .coupon-description {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px dashed #f6a400;
    .amount {
      font-size: 26px;
      color: #f6a400;
    }
  }
}
.welcome-coupon-card.incomingDiscount {
  .coupon-description {
    padding: 8px 0;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "WelcomeCouponCard",
  props: {
    coupon: { type: Object, required: true },
    details: { type: Boolean, default: false },
    type: { type: String, required: true },
    userPoints: { type: Number, default: 0 },
    customCssClass: { type: String, default: "" }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    disabled() {
      console.log(this.type);

      
      if (
        this.isAuthenticated &&
        (this.type =="activeCashbackDiscount" ||
          this.type == "voucher" ||
          this.type == "welcomeCoupon" ||
          (this.coupon.warehousePromo &&
            this.coupon.warehousePromo.promoPoints &&
            this.coupon.warehousePromo.promoPoints <= this.userPoints))
      ) {
        return false;
      } else return true;
    }
  },
  methods: {
    async showDetails() {
      this.$emit("showDetails", this.coupon, this.type, this.customCssClass);
    }
  },
  mounted() {}
};
</script>
